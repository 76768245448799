import { render, staticRenderFns } from "./uploadLogo.vue?vue&type=template&id=b5c1dd5c&scoped=true&"
import script from "./uploadLogo.vue?vue&type=script&lang=js&"
export * from "./uploadLogo.vue?vue&type=script&lang=js&"
import style0 from "./uploadLogo.vue?vue&type=style&index=0&id=b5c1dd5c&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b5c1dd5c",
  null
  
)

export default component.exports